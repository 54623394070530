import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { AbpModule } from '@abp/abp.module';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard, PendingChangesGuard } from './auth/auth-route-guard';
import { AbpPaginationControlsComponent } from './pagination/abp-pagination-controls.component';
import { LocalizePipe } from '@shared/pipes/localize.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';

// PrimeNg Components
import { CardModule } from 'primeng/card';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TabMenuModule } from 'primeng/tabmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputMaskModule } from 'primeng/inputmask';
import { ChipsModule } from 'primeng/chips';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SpinnerModule } from 'primeng/spinner';
import { TabViewModule } from 'primeng/tabview';
import { FileUploadModule } from 'primeng/fileupload';
import { PasswordModule } from 'primeng/password';
import { FieldsetModule } from 'primeng/fieldset';
import { AccordionModule } from 'primeng/accordion';

import { BlockDirective } from './directives/block.directive';
import { BusyDirective } from './directives/busy.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { DatagridComponent } from './datagrid/datagrid.component';
import { ChartsModule } from 'ng2-charts';
import { BarChartComponent } from './barchart/barchart.component';
import { DialogService, MessageService } from 'primeng/api';
import { MomentFormatDateTimePipe, AddYearDateTimePipe, AstMomentFormatDateTimePipe } from './pipes/moment-format.pipe';
import { ValidationBoxComponent } from './validationbox/validationbox.component';
import { NgFormValidationComponent } from './validation/validation.component';
import { DigitOnlyDirective } from './directives/onlyInteger.directive';
import { NullStringFormatPipe } from './pipes/null-string-format.pipe';
import { TwoDecimalFormatPipe, NullTwoDecimalFormatPipe, CurrencyFormatPipe } from './pipes/two-decimal-format.pipe';
import { DataGridCellPipe } from './pipes/data-grid-cell.pipe';

// TODO: Remove all material components from shared module
@NgModule({
    imports: [
        CommonModule,
        AbpModule,
        RouterModule,
        NgxPaginationModule,
        ChartsModule,
        CardModule,
        MenuModule,
        MenubarModule,
        ContextMenuModule,
        TabMenuModule,
        ProgressSpinnerModule,
        TableModule,
        ButtonModule,
        InputTextModule,
        CalendarModule,
        DropdownModule,
        RadioButtonModule,
        CheckboxModule,
        SidebarModule,
        ToastModule,
        FileUploadModule,
        InputTextareaModule,
        AutoCompleteModule,
        InputMaskModule,
        MultiSelectModule,
        ChipsModule,
        TooltipModule,
        DynamicDialogModule,
        OverlayPanelModule,
        DialogModule,
        SpinnerModule,
        PasswordModule,
        FieldsetModule,
        AccordionModule
    ],
    declarations: [
        AbpPaginationControlsComponent,
        DatagridComponent,
        ValidationBoxComponent,
        BarChartComponent,
        MomentFormatDateTimePipe,
        AstMomentFormatDateTimePipe,
        AddYearDateTimePipe,
        LocalizePipe,
        NullStringFormatPipe,
        TwoDecimalFormatPipe,
        NullTwoDecimalFormatPipe,
        CurrencyFormatPipe,
        BlockDirective,
        BusyDirective,
        EqualValidator,
        NgFormValidationComponent,
        DigitOnlyDirective,
        DataGridCellPipe,
    ],
    exports: [
        AbpPaginationControlsComponent,
        DatagridComponent,
        ValidationBoxComponent,
        BarChartComponent,
        LocalizePipe,
        NullStringFormatPipe,
        TwoDecimalFormatPipe,
        NullTwoDecimalFormatPipe,
        CurrencyFormatPipe,
        MomentFormatDateTimePipe,
        AstMomentFormatDateTimePipe,
        AddYearDateTimePipe,
        BlockDirective,
        BusyDirective,
        DigitOnlyDirective,
        EqualValidator,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        ScrollingModule,
        CardModule,
        MenuModule,
        ContextMenuModule,
        TabMenuModule,
        ProgressSpinnerModule,
        TableModule,
        ButtonModule,
        InputTextModule,
        FileUploadModule,
        CalendarModule,
        DropdownModule,
        RadioButtonModule,
        CheckboxModule,
        ToastModule,
        TabViewModule,
        SidebarModule,
        InputTextareaModule,
        AutoCompleteModule,
        InputMaskModule,
        MultiSelectModule,
        ChipsModule,
        DynamicDialogModule,
        OverlayPanelModule,
        DialogModule,
        TooltipModule,
        NgFormValidationComponent,
        SpinnerModule,
        PasswordModule,
        FieldsetModule,
        AccordionModule,
        DataGridCellPipe,
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AppAuthService,
                AppRouteGuard,
                PendingChangesGuard,
                DialogService,
                MessageService,
                MomentFormatDateTimePipe,
                AstMomentFormatDateTimePipe,
                AddYearDateTimePipe,
                TwoDecimalFormatPipe,
                CurrencyFormatPipe
            ]
        };
    }
}
