import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { TokenAuthServiceProxy } from '../service-proxies/service-proxies';

@Injectable()
export class AppAuthService {
    constructor(
        private _tokenAuthServiceProxy: TokenAuthServiceProxy,
    ) {
    }

    logout(reload?: boolean): void {
        this._tokenAuthServiceProxy.logout().subscribe();

        abp.auth.clearToken();
        abp.utils.setCookieValue(AppConsts.authorization.encryptedAuthTokenName, undefined, undefined, abp.appPath);
        if (reload !== false) {
            location.href = AppConsts.appBaseUrl;
        }
    }
}
