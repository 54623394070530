import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from '@abp/abpHttpInterceptor';

import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.SecurityQuestionsServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.UserRegisteredAgentServiceProxy,
        ApiServiceProxies.RequestFeeCertificateServiceProxy,
        ApiServiceProxies.BatchServiceProxy,
        ApiServiceProxies.DashboardServiceProxy,
        ApiServiceProxies.RequestV2ServiceProxy,
        ApiServiceProxies.RefundRequestServiceProxy,
        ApiServiceProxies.SecurityQuestionsServiceProxy,
        ApiServiceProxies.BusinessActivityServiceProxy,
        ApiServiceProxies.FeeServiceProxy,
        ApiServiceProxies.FinancialAccountServiceProxy,
        ApiServiceProxies.LookupDataServiceProxy,
        ApiServiceProxies.RequestFeeServiceProxy,
        ApiServiceProxies.NameReservationServiceProxy,
        ApiServiceProxies.RegisteredAgentServiceProxy,
        ApiServiceProxies.ServiceTypeServiceProxy,
        ApiServiceProxies.CompanyRegistrationServiceProxy,
        ApiServiceProxies.RequestV2ServiceProxy,
        ApiServiceProxies.TransactionHeaderServiceProxy,
        ApiServiceProxies.ReceiptServiceProxy,
        ApiServiceProxies.AccountTransactionServiceProxy,
        ApiServiceProxies.FileRecordServiceProxy,
        ApiServiceProxies.DataMigrationServiceProxy,
        ApiServiceProxies.AnnualRenewalServiceProxy,
        ApiServiceProxies.ServiceRequestServiceProxy,
        ApiServiceProxies.CompanyTypeServiceProxy,
        ApiServiceProxies.CommentServiceProxy,
        ApiServiceProxies.DocumentServiceProxy,
        ApiServiceProxies.ReportServiceProxy,
        ApiServiceProxies.EntityPropertyChangeServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
