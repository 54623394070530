import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'momentFormatDateTime' })
export class MomentFormatDateTimePipe implements PipeTransform {
    transform(value: moment.MomentInput, format: string) {
        if (!value) {
            return '';
        }

        return moment.utc(value).local().format(format);
    }
}

@Pipe({ name: 'astMomentFormatDateTime' })
export class AstMomentFormatDateTimePipe implements PipeTransform {
    transform(value: moment.MomentInput, format: string) {
        if (!value) {
            return '';
        }

        return moment.utc(value).utcOffset("-04:00").format(format);
    }
}

@Pipe({ name: 'momentFormatAddYear' })
export class AddYearDateTimePipe implements PipeTransform {
    transform(value: moment.MomentInput, numberOfYears: number) {
        if (!value) {
            return '';
        }

        if (numberOfYears) moment.utc(value).local().format('DD-MM-YYYY');
        return moment.utc(value).add(numberOfYears, 'year').local().format('DD-MM-YYYY');
    }
}