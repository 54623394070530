import { Injectable } from '@angular/core';
import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppSessionService } from '../session/app-session.service';

import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    CanDeactivate
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild {
    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this._sessionService.user) {
            this._router.navigate(['/account/login']);
            return false;
        }

        if (this._sessionService.userDetails) {
            if (this._sessionService.userDetails.isUsingTemporaryPassword && !this._sessionService.userDetails.isSecurityQuestionsAnswered) {
                this._router.navigate([`account/questions/${this._sessionService.userDetails.id}`]);
                return false;
            }
            if (this._sessionService.userDetails.isUsingTemporaryPassword) {
                this._router.navigate(['account/reset-password']);
                return false;
            }
        }

        if (!route.data || !route.data['permission']) {
            return true;
        }

        if (this._permissionChecker.isGranted(route.data['permission'])) {
            return true;
        }

        this._router.navigate([this.selectBestRoute()]);
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    selectBestRoute(): string {
        if (!this._sessionService.user) {
            return '/account/login';
        }

        if (this._permissionChecker.isGranted('CR.UserManagement')
            || this._permissionChecker.isGranted('RA.UserManagement')) {
            return '/app/admin/users';
        }

        return '/app/home';
    }
}

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        // if there are no pending changes, just allow deactivation; else confirm first
        return component.canDeactivate() ?
            true :
            // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
            // when navigating away from your angular app, the browser will show a generic warning message
            Observable.create((observer) => {
                abp.message.confirm(
                    "You have unsaved changes. Press Cancel to go back and save these changes, or Yes to discard these changes.",
                    "Discard Changes?",
                    (isConfirmed) => {
                        observer.next(isConfirmed);
                        observer.complete();
                    }
                );
            });
    }
}
