import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'twoDecimalFormat' })
export class TwoDecimalFormatPipe implements PipeTransform {
    transform(value: any) {
        if (!value /*|| !Number.isSafeInteger(value)*/) {
            return "0.00";
        }

        if (value < 0)
            return "(" + this.getFormattedValue(value, true) + ")";

        return this.getFormattedValue(value);
    }

    getFormattedValue(value: any, negate: boolean = false) {
        return parseFloat((parseFloat(value) * (negate ? -1 : 1)).toFixed(2))
            .toLocaleString(undefined, { useGrouping: true, minimumFractionDigits:2,maximumFractionDigits:2 });
    }
}

@Pipe({ name: 'nullTwoDecimalFormat' })
export class NullTwoDecimalFormatPipe implements PipeTransform {
    transform(value: any) {
        if (value === undefined || value === null) {
            return "-";
        }

        if (value < 0)
            return "$(" + this.getFormattedValue(value, true) + ")";

        return "$" + this.getFormattedValue(value);
    }

    getFormattedValue(value: any, negate: boolean = false) {
        return parseFloat((parseFloat(value) * (negate ? -1 : 1)).toFixed(2))
            .toLocaleString(undefined, { useGrouping: true, minimumFractionDigits:2,maximumFractionDigits:2 });
    }
}

@Pipe({ name: 'currencyFormat' })
export class CurrencyFormatPipe implements PipeTransform {
    transform(value: any) {
        if (value === undefined || value === null) {
            return "$0.00";
        }

        if (value < 0)
            return "$(" + this.getFormattedValue(value, true) + ")";

        return "$" + this.getFormattedValue(value);
    }

    getFormattedValue(value: any, negate: boolean = false) {
        return parseFloat((parseFloat(value) * (negate ? -1 : 1)).toFixed(2))
            .toLocaleString(undefined, { useGrouping: true, minimumFractionDigits:2,maximumFractionDigits:2 });
    }
}
